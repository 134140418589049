<script setup>
import AppLogo from "@images/app.png";
import AppSquareLogo from "@images/app-square.png";

const props = defineProps({
    square: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <img :src="props.square ? AppSquareLogo : AppLogo" />
</template>
